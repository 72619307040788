import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  GridHelper,
  HemisphereLight,
  sRGBEncoding,
  Color,
  MeshBasicMaterial,
} from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import "../components/scroll-controls/camera-helper.css"

const CameraHelper: React.FC = () => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      const {
        CameraRig,
        ThreeDOFControls,
        CameraHelper,
        FreeMovementControls,
        // eslint-disable-next-line @typescript-eslint/no-var-requires
      } = require("three-story-controls")
      const canvasParent = document.querySelector(".canvas-parent")
      const loading = document.querySelector(".loading")

      // Set up the three.js scene
      const scene = new Scene()
      const camera = new PerspectiveCamera(
        75,
        canvasParent.offsetWidth / canvasParent.offsetHeight,
        0.1,
        1000
      )
      const renderer = new WebGLRenderer()
      renderer.setSize(canvasParent.offsetWidth, canvasParent.offsetHeight)
      renderer.outputEncoding = sRGBEncoding
      scene.background = new Color(0x1d1d1d)
      canvasParent.appendChild(renderer.domElement)

      // Initialize loaders
      const loader = new GLTFLoader()
      const dracoLoader = new DRACOLoader()
      // dracoLoader.setDecoderConfig({ type: 'js' });
      dracoLoader.setDecoderPath(
        "https://unpkg.com/three@0.148.0/examples/jsm/libs/draco/"
      )
      loader.setDRACOLoader(dracoLoader)

      const light = new HemisphereLight(0xffffbb, 0x080820, 1)
      scene.add(light)

      const grid = new GridHelper(100, 50)
      grid.position.set(0, -5, 0)
      scene.add(grid)

      // Initialize the CameraRig
      const rig = new CameraRig(camera, scene)
      let scrollControls, threeDOFControls, cameraHelper

      // Initialize the controls
      const initializeControls = () => {
        scrollControls = new FreeMovementControls(rig, {
          domElement: canvasParent,
        })
        scrollControls?.enable()

        cameraHelper = new CameraHelper(
          rig,
          scrollControls,
          renderer.domElement
        )

        // Set up ThreeDOFControls
        // See https://nytimes.github.io/three-story-controls/docs/three-story-controls.threedofcontrols.html for more
        threeDOFControls = new ThreeDOFControls(rig, {
          panFactor: Math.PI / 10,
          tiltFactor: Math.PI / 10,
          truckFactor: 0,
          pedestalFactor: 0,
        })

        // Enable controls
        scrollControls?.enable()
        threeDOFControls.enable()
      }

      // Ensure model materials are not lit
      const resetMaterials = model => {
        model.scene.traverse(child => {
          if (child.isMesh) {
            const map = child.material.map
            child.material = new MeshBasicMaterial({ map })
          }
        })
      }

      // Wait for both assets to load to hide the loading text and initialize controls
      Promise.all([loader.loadAsync("/three-d-files/world.glb")])
        .then(assets => {
          const [model] = assets
          resetMaterials(model)
          initializeControls()
          scene.add(model.scene)
          loading.style.display = "none"
          render()
        })
        .catch(e => console.log(e))

      // Render loop
      const render = t => {
        // console.log('RENDERING')
        if (rig.hasAnimation) {
          scrollControls?.update(t)
          threeDOFControls?.update(t)
        }
        renderer.render(scene, camera)
        cameraHelper?.update(t)
        window.requestAnimationFrame(render)
      }

      // Handle window resize events
      window.addEventListener("resize", () => {
        camera.aspect = canvasParent.clientWidth / canvasParent.clientHeight
        camera.updateProjectionMatrix()
        renderer.setSize(canvasParent.clientWidth, canvasParent.clientHeight)
      })
    }
  }, [])

  return (
    <Layout>
      <SEO title="OREMOB BASE" />
      <>
        <div className="scene camera-helper">
          <div className="canvas-parent">
            <p className="loading"> Loading ... </p>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default CameraHelper
